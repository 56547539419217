<template>
  <model-details
    :key="key"
    class="event-details"
    :fetch-model="fetchPost"
    @model-loaded="handleEventLoaded"
  >
    <post-row
      v-if="post"
      :post="post"
      disable-store
      class="mb-1 mb-lg-2 mb-xl-3 post-row"
      @edit-post="isEditingPost = true"
      @toggle-like="handleToggleLike"
      @comment-created="commentCreated"
    />
    <post-creation-form-modal v-model="isEditingPost" :post="post" @posts-updated="key += 1" />
  </model-details>
</template>

<script>
import ModelDetails from '@core/components/model-details/ModelDetails.vue';
import PostRow from '@/views/apps/posts/components/PostRow.vue';
import PostCreationFormModal from '@/views/apps/posts/components/post-creation/PostCreationFormModal.vue';

import Service from '@/config/service-identifiers';

export default {
  name: 'PostsDetails',
  components: { ModelDetails, PostRow, PostCreationFormModal },
  props: {
    postKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      key: 0,
      post: null,
      isEditingPost: false,
    };
  },
  methods: {
    handleEventLoaded(post) {
      this.post = post;
    },
    async fetchPost() {
      const response = await this.$store.$service[Service.BackendClient].get('posts', {
        params: {
          communityKey: this.$store.getters.currentCollective.key,
          postKeys: [this.postKey],
        },
      });

      return response.data.data[0];
    },
    handleToggleLike() {
      // FIXME: This method should be modified when post is removed from copernicData,
      // so that all lists of posts use the same logic
      this.post = {
        ...this.post,
        likedByMember: !this.post.likedByMember,
        totalLikes: this.post.totalLikes + (this.post.likedByMember ? -1 : +1),
      };
    },
    commentCreated() {
      // FIXME: This method should be modified when post is removed from copernicData,
      // so that all lists of posts use the same logic
      this.post = {
        ...this.post,
        commentCount: this.post.commentCount + 1,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.post-row::v-deep {
  .file-preview__content {
    height: calc(100vh - 300px);
  }
}
</style>
