<template>
  <section class="event-details">
    <slot v-if="isLoading" name="loading">
      <b-spinner
        type="grow"
        small
        class="mx-auto mt-3 d-block"
      />
    </slot>
    <slot v-else :model="model" />
  </section>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';

export default {
  name: 'ModelDetails',
  components: { BSpinner },
  props: {
    fetchModel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      model: null,
    };
  },
  async created() {
    this.isLoading = true;
    this.model = await this.fetchModel();

    if (!this.model) {
      this.$router.push({
        name: 'error',
        params: {
          communityId: this.$route.params.communityId,
          error: '404',
        },
      });
      this.isLoading = false;

      return;
    }

    this.isLoading = false;

    this.$emit('model-loaded', this.model);
  },
};
</script>
